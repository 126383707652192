<template>
  <div class="home">
    <Header />
    <!-- 技术轮播图 -->
    <div class="home_banner">
      <img
        src="@/assets/jishu/banner_01.jpg"
        alt=""
      />
      <div class="banner_text">
        <div>先进的系统架构和稳定的业务逻辑</div>
      </div>
    </div>
    <!-- 部署架构 -->
    <div class="row">
      <div class="row-title">
        <div
          :class="{titleActive:titleRow,titleActiveF:!titleRow}"
          class="title"
          @mouseenter="titleRow = true"
          @mouseleave="titleRow = false"
        >核心系统 - 部署架构</div>
        <div class="title-two">前后端分离，支持跨平台部署，支持集群部署；可实现高性能、高负载、高可用的部署方案。</div>
      </div>
      <div class="row-a">
        <img
          src="@/assets/jishu/bsjg_01.png"
          alt=""
        />
        <img
          src="@/assets/jishu/bsjg_02.png"
          alt=""
        />
        <img
          src="@/assets/jishu/bsjg_03.png"
          alt=""
        />
        <img
          src="@/assets/jishu/bsjg_04.png"
          alt=""
        />
        <img
          src="@/assets/jishu/bsjg_05.png"
          alt=""
        />
        <img
          src="@/assets/jishu/bsjg_06.png"
          alt=""
        />
        <img
          src="@/assets/jishu/bsjg_07.png"
          alt=""
        />
      </div>
    </div>
    
    <!-- 部署架构 -->
    <div class="row">
      <div class="row-title app-title">
        <div
          :class="{titleActive:titleRow,titleActiveF:!titleRow}"
          class="title"
          @mouseenter="titleRow = true"
          @mouseleave="titleRow = false"
        >核心系统 - 技术架构</div>
        <div class="title-two">采用现阶段流行技术，紧跟潮流，更新快，配合专属代码生成器，易学易用，开发效率高。</div>
      </div>
      <div class="tableBox">
        <table>
          <colgroup>
            <col width="15%">
            <col width="35%">
            <col width="15%">
            <col width="35%">
          </colgroup>
          <tbody>
            <tr>
              <th>核心框架</th>
              <td>Asp.net Core 5</td>
              <th>Api框架</th>
              <td>Asp.net Core WebApi</td>
            </tr>
            <tr>
              <th>IOC框架</th>
              <td>AutoFac</td>
              <th>文档</th>
              <td>Swashbuckle（Swagger2）</td>
            </tr>
            <tr>
              <th>授权认证</th>
              <td>JWT</td>
              <th>消息队列</th>
              <td>RedisMQ / InitQ</td>
            </tr>
            <tr>
              <th>实体映射</th>
              <td>AutoMapper</td>
              <th>ORM框架</th>
              <td>SqlSugar</td>
            </tr>
            <tr>
              <th>代码生成器</th>
              <td>DotLiquid</td>
              <th>富文本</th>
              <td>CKEditor5</td>
            </tr>
            <tr>
              <th>序列化</th>
              <td>Newtonsoft.Json</td>
              <th>缓存框架</th>
              <td>StackExchange.Redis / Redis</td>
            </tr>
            <tr>
              <th>即时通讯</th>
              <td>SignalR</td>
              <th>数据表格</th>
              <td>NPOI</td>
            </tr>
            <tr>
              <th>日志组件</th>
              <td>Nlog</td>
              <th>定时任务</th>
              <td>HangFire</td>
            </tr>
            <tr>
              <th>在线支付</th>
              <td>Paylink</td>
              <th>微信SDK</th>
              <td>SKIT.FlurlHttpClient.Wechat </td>
            </tr>
            <tr>
              <th>数据库</th>
              <td>Mysql / Mssql</td>
              <th>前端框架</th>
              <td>UniApp</td>
            </tr>
            <tr>
              <th>后端UI框架</th>
              <td>LayUIAdmin</td>
              <th>前端UI框架</th>
              <td>UView</td>
            </tr>
            <tr>
              <th>存储</th>
              <td>Tencent.QCloud.Cos.Sdk / Aliyun.OSS.Sdk</td>
              <th>雪花算法</th>
              <td>Yitter SnowFlake IdGenerator</td>
            </tr>
            <tr>
              <th>安全过滤</th>
              <td>ToolGood.Words非法词(敏感词)检测组件</td>
              <th>中介者</th>
              <td>MediatR</td>
            </tr>
            <tr>
              <th>第三方工具</th>
              <td colspan="3">
                易联云小票打印机SDK / 凯信通短信SDK / 易源快递查询SDK / Lodop打印控件
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- 部署架构 -->
    <div class="row">
      <div class="row-title">
        <div
          :class="{titleActive:titleRow,titleActiveF:!titleRow}"
          class="title"
          @mouseenter="titleRow = true"
          @mouseleave="titleRow = false"
        >核心系统 - 功能全景</div>
        <div class="title-two">商城功能完善，适用大多数电商场景，营销活动丰富，帮助企业快速获客拉新，提升产品销量。</div>
      </div>
      <div class="row-a">
        <img
          src="@/assets/jishu/gn_01.png"
          alt=""
        />
        <img
          src="@/assets/jishu/gn_02.png"
          alt=""
        />
        <img
          src="@/assets/jishu/gn_03.png"
          alt=""
        />
        <img
          src="@/assets/jishu/gn_04.png"
          alt=""
        />
        <img
          src="@/assets/jishu/gn_05.png"
          alt=""
        />
        <img
          src="@/assets/jishu/gn_06.png"
          alt=""
        />
        <img
          src="@/assets/jishu/gn_07.png"
          alt=""
        />
        <img
          src="@/assets/jishu/gn_08.png"
          alt=""
        />
        <img
          src="@/assets/jishu/gn_09.png"
          alt=""
        />
      </div>
    </div>
    <!-- 部署架构 -->
    <div class="row">
      <div class="row-title">
        <div
          :class="{titleActive:titleRow,titleActiveF:!titleRow}"
          class="title"
          @mouseenter="titleRow = true"
          @mouseleave="titleRow = false"
        >核心系统 - 商城优点</div>
        <div class="title-two">顺应时代发展，节省成本开支</div>
      </div>
      <div class="row-a">
        <img
          src="@/assets/jishu/bk_18_01.png"
          style="width:100%;"
          alt=""
        />
        <img
          src="@/assets/jishu/bk_18_02.png"
          style="width:100%;"
          alt=""
        />
        <img
          src="@/assets/jishu/bk_18_03.png"
          style="width:100%;"
          alt=""
        />
        <img
          src="@/assets/jishu/bk_18_04.png"
          style="width:100%;"
          alt=""
        />
        <img
          src="@/assets/jishu/bk_19_01.png"
          style="width:100%;"
          alt=""
        />
        <img
          src="@/assets/jishu/bk_19_02.png"
          style="width:100%;"
          alt=""
        />
        <img
          src="@/assets/jishu/bk_19_03.png"
          style="width:100%;"
          alt=""
        />
        <img
          src="@/assets/jishu/bk_19_04.png"
          style="width:100%;"
          alt=""
        />
        <img
          src="@/assets/jishu/bk_19_05.png"
          style="width:100%;"
          alt=""
        />
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
export default {
  data() {
    return {
      titleRow: false,
    };
  },
};
</script>

<style lang="less" scoped>
.home {
  img {
      display: block!important;;
      width: 100%;
      height: 100%;
    }
  .home_banner {
    padding-top: 80px;
    position: relative;
    width: 100%;
    // height: 200px;
    img {
      display: block!important;;
      width: 100%;
      height: 100%;
    }
    .banner_text {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 40px;
      font-weight: 700;
      letter-spacing: 5px;
    }
  }
  .row {
    width: 1200px;
    margin: 0 auto;
    padding: 50px 0 0px;
    .row-title {
      font-size: 22px;
      text-align: center;
      margin-bottom: 10px;
      .title {
        cursor: pointer;
        display: inline-block;
        position: relative;
        margin-bottom: 20px;
      }
      .title::after {
        content: "";
        position: absolute;
        bottom: -5px;
        left: 0;
        width: 50%;
        height: 2px;
        background-color: rgb(237, 37, 52);
      }
      .title-two {
        font-size: 14px;
        color: #333;
      }
    }

    .tableBox{
        background: #fff;
        padding: 10px;
        overflow: hidden;
        width: calc(100% - 20px);
        height: 100%;
        table{
            border-collapse: collapse;
            margin: 0 auto;
            text-align: center;
            width: 100%;
            border-radius: 20px;
            tr:nth-child(even){
                background: #f5fafa;
            }
            tr:nth-child(odd){
                background: #fff;
            }
            th{
                border: 2px dashed #cad9ea;
                color: #666;
                line-height: 80px;
                font-size: 16px;
                background: #f2f7fd;
            }
            td{
                border: 2px dashed #cad9ea;
                color: #666;
                line-height: 80px;
                font-size: 16px;
            }
        }
    }
  }
}
</style>