<template>
    <div class="home">
        <Header/>
        <div class="home_img">
            <img src="../assets/home/pro_01.jpg" alt="">
            <img src="../assets/home/pro_02.jpg" alt="">
            <img src="../assets/home/pro_03.jpg" alt="">
            <img src="../assets/home/pro_04.jpg" alt="">
            <img src="../assets/home/pro_05.jpg" alt="">
            <img src="../assets/home/pro_06.jpg" alt="">
            <img src="../assets/home/pro_07.jpg" alt="">
            <img src="../assets/home/pro_08.jpg" alt="">
            <img src="../assets/home/pro_09.jpg" alt="">
            <img src="../assets/home/pro_10.jpg" alt="">
            <img src="../assets/home/pro_11.jpg" alt="">
            <img src="../assets/home/pro_12.jpg" alt="">
            <img src="../assets/home/pro_13.jpg" alt="">
            <img src="../assets/home/pro_14.jpg" alt="">
            <img src="../assets/home/pro_15.jpg" alt="">
            <img src="../assets/home/pro_16.jpg" alt="">
        </div>
        <Footer></Footer>
    </div>
</template>
<script>
export default {
    data(){
        return{}
    }
}
</script>
<style lang="less" scoped>
.home_img{
    width: 100%;
    img{
        display: block;
        width: 100%;
    }
}
</style>