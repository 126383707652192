<template>
  <div class="home lixiwomen">
    <Header/>
    <!-- banner -->
    <div class="banner">
      <img src="@/assets/abous.jpg" alt=""/>
    </div>
    <div class="about">
      <div class="about_title">联系我们</div>
      <div class="about_content">
        <div class="about_content_l">
          <div class="about_content_title">杭州真琦科技有限公司，专业从事软件开发、技术咨询、技术服务人工智能等多样化的信息软件公司。近年来致力于企业信息化，移动端开发，软件开发，物联网开发，网站建设，自媒体一键分发平台，工作流的研发、UI原型设计，软件服务,公司以前沿的技术为优势、以技术创新为核心、以优质服务为支撑，致力于为企业和个人提供软件和设计一站式定制服务。</div>
          <div class="about_info">
            <div class="about_item">
              <div class="about_label">手机号码:</div>
              <div class="about_value">15356103840</div>
            </div>
            <div class="about_item">
              <div class="about_label">微信号:</div>
              <div class="about_value">15356103840</div>
            </div>
            <div class="about_item">
              <div class="about_label">联系邮箱:</div>
              <div class="about_value">Boss@gcjlkj.com</div>
            </div>
          </div>
          <div class="about_info">
            <div class="about_label">交通:</div>
            <div class="about_value">地铁5号线创景路C出口，近阿里巴巴总部，浙一医院</div>
          </div>
          <div class="about_info">
            <div class="about_label">联系地址:</div>
            <div class="about_value">浙江省杭州市余杭区未来科技城海创科技3幢905</div>
          </div>
          <div class="about_txt">对公账户</div>
          <div class="about_info">
            <div class="about_label">账户名称:</div>
            <div class="about_value">杭州真琦科技有限公司</div>
          </div>
          <div class="about_info">
            <div class="about_label">账户号码:</div>
            <div class="about_value">201000230116367</div>
          </div>
          <div class="about_info">
            <div class="about_label">开户银行:</div>
            <div class="about_value">浙江杭州余杭农村商业银行股份有限公司未来科技城支行</div>
          </div>
        </div>
        <div class="about_content_r">
          <div class="about_content_item" @click="openUrl('http://www.gcjlkj.com/state/yingyezhizhao.jpg')">
            <img src="@/assets/yingyezhizhao.jpg" alt="" style="width: 185px;height:135px;"/>
            <div class="txt">营业执照</div>
          </div>
          <div class="about_content_item" @click="openUrl('http://www.gcjlkj.com/state/01.jpg')">
            <img src="@/assets/rz.jpg" style="width: 102px;height:135px;"/>
            <div class="txt">软件著作权证</div>
          </div>
        </div>
      </div>
    </div>
    <div class="lixiwomen_tab">
      <div class="lixiwomen_tab_tit">
        <div class="lixiwomen_tab_item" :class="{ac_lixiwomen_tab_item:lixiwomen_tab_index == index}" v-for="(item,index) in tabList" :key="index" @click="lixiwomen_tab_index = index">
          <div class="lixiwomen_tab_name">{{item.title}}</div>
          <div class="lixiwomen_tab_info">{{item.info}}</div>
        </div>
      </div>
      <div class="lixiwomen_tab_img">
        <img :src="lixiwomen_tab_index == 0 ? require('@/assets/hzd.png'):require('@/assets/geh.png')" alt="">
      </div>
    </div>
    <div class="about">
      <div class="about_title">公司位置</div>
      <div id="container"></div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
let map;
export default {
  data(){
    return{
      markerLayer: {},
      lixiwomen_tab_index: 0,
      tabList: [
        {
          title: '对公付款',
          info: '请按照公司银行开户账户信息准确填写汇款单'
        },{
          title: '个人付款',
          info: '以下收款人为公司指定财务人员，请您放心汇款'
        }
      ],
    }
  },
  mounted(){
    let that = this;
    map = new TMap.Map('container', {
      zoom: 17.2,
      center: new TMap.LatLng(30.276241, 119.996169),
    });
    let markerLayer = new TMap.MultiMarker({
      map: map,
      geometries: [
        {
          "id": "2",
          "styleId": 'marker',
          "position": new TMap.LatLng(30.276241, 119.996169),
          "properties": {
              "title": "海创科技3幢905"
          }
        }
      ]
    })
  },
  destroyed(){
    map.destroy()
    console.log("页面卸载")
  },
  methods:{
    openUrl(url){
      window.open(url)
    },
  }
}
</script>
<style lang="less" scoped>
.banner{
  padding-top: 80px;
  width: 100%;
  height: 400px;
  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top left;
  }
}
.lixiwomen_tab{
  width: 1300px;
  margin: 20px auto;
  .lixiwomen_tab_tit{
    display: flex;
    
    .lixiwomen_tab_item{
      padding: 10px 30px;
      border: 1px solid #ccc;
      text-align: center;
      cursor: pointer;
      width: 200px;
      .lixiwomen_tab_name{
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 5px;
      }
      .lixiwomen_tab_info{
        font-size: 14px;
        color: #666;
      }
    }
    .ac_lixiwomen_tab_item{
      border: 1px solid rgb(237, 37, 52);
      .lixiwomen_tab_name{
        color: rgb(237, 37, 52);
      }
    }
  }
  .lixiwomen_tab_img{
    width: 800px;
    margin: 20px 0 0;
    img{
      display: block;
      width: 100%;
    }
  }
}
.about{
  width: 1300px;
  margin: 0 auto;
  .about_title{
    height: 80px;
    font-size: 28px;
    display: flex;
    align-items: center;
  }
  .about_content{
    display: flex;
    align-items: center;
    border-left: 5px solid rgb(237, 37, 52);
    padding: 10px;
    box-sizing: border-box;
    .about_content_l{
      flex: 1;
      .about_content_title,.about_txt{
        font-size: 16px;
        margin-bottom: 10px;
        color: #333;
        line-height: 22px;
      }
      .about_item{
        display: flex;
        align-items: center;
        margin-right: 8px;
      }
      .about_info{
        display: flex;
        align-items: center;
        margin-bottom: 5px;
      }
      .about_label{
        color: #666;
        margin-right: 5px;
      }
      .about_value{
        color: #333;
      }
    }
    .about_content_r{
      width: 350px;
      display: flex;
      align-items: center;
      cursor: pointer;
      justify-content: space-between;
      .about_content_item{
        text-align: center;
      }
    }
  }
}

</style>
