<template>
    <div class="home">
        <Header/>
        <!-- banner -->
        <div class="banner w_width">
            <div class="banner_l">
                <div class="banner_title">用户案例</div>
                <div class="banner_info_txt">开发的各类UniAPP、H5商城、Android应用、微信小程序、微信公众号、支付宝小程序、百度小程序、字节跳动小程序、快应用、QQ小程序、360小程序、快手小程序、飞书小程序、钉钉小程序等用户上线作品展示</div>
            </div>
            <div class="banner_r">
                <img src="@/assets/userDemo/case.png" alt=""/>
            </div>
        </div>
        <!-- 用户案例 -->
        <div class="demo w_width">
            <div class="demo_title">用户案例</div>
            <div class="demo_ul">
                <div v-for="(item,index) in demoList" :key="index" class="demo_item">
                    <div class="demo_qr">
                        <img :src="item.wxImg" alt="">
                    </div>
                    <div class="demo_item_title">
                        {{item.name}}
                        <div class="demo_item_icon miniprogram" :style="{
                            'border-radius': '50%',
                            'background-position': item.type * 30 * -1 + 'px 0'
                        }"></div>
                    </div>
                    <div class="demo_info">{{item.info}}</div>
                    <div class="demo_type">
                        <div class="demo_type_label">
                            行业：
                            <div class="demo_type_val">{{item.hy}}</div>
                        </div>
                        <div class="demo_type_label">
                            平台：
                            <div class="demo_type_val">{{item.pt}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>
<script>
export default {
    data(){
        return{
            demoList: [
                {
                    wxImg: require('@/assets/userDemo/yjsp.png'),
                    name: '宇杰食品',
                    info: '您身边的生鲜购物助手，轻松选购全球高品质食材，开启健康品质生活。',
                    hy: '水果生鲜',
                    pt: '微信小程序',
                    type: 2
                },
                {
                    wxImg: require('@/assets/userDemo/gxwl.jpg'),
                    name: '耕喜文旅',
                    info: '分享小而美、在地化、有温度的美好事物。',
                    hy: '旅游',
                    pt: '微信小程序',
                    type: 2
                },
                {
                    wxImg: require('@/assets/userDemo/kxy.jpg'),
                    name: '康颐馨',
                    info: '康颐馨健康生活平台',
                    hy: '保健',
                    pt: '微信小程序',
                    type: 2
                },
                {
                    wxImg: require('@/assets/userDemo/yllzb.jpg'),
                    name: '御玲珑珠宝',
                    info: '原创设计师珠宝',
                    hy: '珠宝',
                    pt: '微信小程序',
                    type: 2
                },
                // 不能使用
                {
                    wxImg: require('@/assets/userDemo/jmgy.jpg'),
                    name: '湘猫公寓',
                    info: '集猫咪售卖、猫舍、喵咪用品等一站式服务平台',
                    hy: '宠物',
                    pt: '微信小程序',
                    type: 2
                },
                {
                    wxImg: require('@/assets/userDemo/kll.jpg'),
                    name: '干掉写PPT的',
                    info: '干活的累死累活，到头来干不过写PPT的。',
                    hy: '办公类',
                    pt: '微信小程序',
                    type: 2
                },
                {
                    wxImg: require('@/assets/userDemo/czyp.jpg'),
                    name: '车之壹品',
                    info: '专注汽车用品，点缀驾生活',
                    hy: '汽贸',
                    pt: '微信小程序',
                    type: 2
                },
                // 不能使用
                {
                    wxImg: require('@/assets/userDemo/wcfx.jpg'),
                    name: '徽常放心',
                    info: '精选商品推荐，不好不推荐放心选购！',
                    hy: '网络',
                    pt: '微信小程序',
                    type: 2
                },
                {
                    wxImg: require('@/assets/userDemo/zmcq.jpg'),
                    name: '臻蜜传奇',
                    info: '主营农副产品、扶贫产品等销售，帮扶云南等贫困企业和贫困户，打造现代农牧业市场化标品产业链。',
                    hy: '农副产品',
                    pt: '微信小程序',
                    type: 2
                },
                {
                    wxImg: require('@/assets/userDemo/qays.jpg'),
                    name: '千爱云商',
                    info: '千爱云商公司项目',
                    hy: '医疗美容',
                    pt: '微信小程序',
                    type: 2
                },
                {
                    wxImg: require('@/assets/userDemo/znqm.jpg'),
                    name: '智能起名',
                    info: '提供在线起名业务。',
                    hy: '服务',
                    pt: '微信小程序',
                    type: 2
                },
                {
                    wxImg: require('@/assets/userDemo/gsds.jpg'),
                    name: '广商订水',
                    info: '提供各类订购服务',
                    hy: '服务',
                    pt: '微信小程序',
                    type: 2
                },
                {
                    wxImg: require('@/assets/userDemo/hpsc.jpg'),
                    name: '厚朴商城',
                    info: '与深圳上市公司星链科技供应商合作，共同打造更优质、更低价的正品网购平台。',
                    hy: '商贸',
                    pt: '微信小程序',
                    type: 2
                },
                {
                    wxImg: require('@/assets/userDemo/znmj.jpg'),
                    name: '智能美肌',
                    info: '智能美容，为你创造美。',
                    hy: '美容',
                    pt: '微信小程序',
                    type: 2
                }
            ]
        }
    },
}
</script>
<style lang="less" scoped>
.home{
    .banner{
        padding-top: 80px;
        display: flex;
        align-items: center;
        margin: 0px auto;
        .banner_l,.banner_r{
            width: 50%;
            .banner_title{
                font-weight: 500;
                font-size: 34px;
                margin-bottom: 32px;
            }
            .banner_info_txt{
                margin-top: 32px;
                font-size: 16px;
                line-height: 28px;
                color: #666;
            }
        }
        .banner_r{
            padding: 10px;
            text-align: right;
        }
    }
    .demo{
        margin: 0 auto;
        .demo_title{
            text-align: center;
            font-size: 24px;
            margin: 30px 0;
            color: #333;
        }
        .demo_ul{
            display: flex;
            flex-wrap: wrap;
            // justify-content: space-around;
            .demo_item{
                width: 280px;
                height: 390px;
                position: relative;
                background: #FFF;
                border-radius: 8px 8px 4px 4px;
                box-shadow: 0 0 15px 6px #eee;
                overflow: hidden;
                margin-right: 20px;
                margin-bottom: 25px;
                padding: 26px 12px;
                box-sizing: border-box;
                cursor: pointer;
                .demo_qr{
                    width: 100%;
                    height: 180px;
                    overflow: hidden;
                    text-align: center;
                    border-bottom: 1px dashed #eee;
                    margin-bottom: 10px;
                    img{
                        width: 160px;
                        height: 160px;
                    }
                }
                .demo_item_title{
                    width: 100%;
                    color: #3e4552;
                    font-weight: 500;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    font-size: 18px;
                    line-height: 30px;
                    position: relative;
                    .demo_item_icon{
                        width: 30px;
                        height: 30px;
                        border: 3px solid #fff;
                        box-shadow: 0 0 5px 0 #eee;
                        position: absolute;
                        top: 0;
                        right: 0;
                        background-image: url('../assets/userDemo/caseLogo.png');
                    }
                }
                .demo_info{
                    color: #999;
                    font-size: 13px;
                    line-height: 24px;
                    margin: 20px 0;
                    height: 50px;
                    overflow: hidden;
                }
                .demo_type{
                    display: flex;
                    align-items: center;
                    .demo_type_label{
                        display: flex;
                        align-items: center;
                        color: #999;
                        font-size: 10px;
                    }
                    .demo_type_val{
                        color: #3e4552;
                        font-size: 10px;
                        border: 1px solid #dbdddf;
                        border-radius: 16px;
                        margin-right: 5px;
                        padding: 3px 8px;
                        white-space: nowrap;
                        max-width: 95px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }
        }
    }
}
</style>